import {
  Avatar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import programClient from "../api/programClient";
import OrgSwitcher from "../components/OrgSwitcher";
import YarsCardHeader from "../components/YarsCardHeader";
import NavLinkForward from "../navigation/NavLinkForward";

export const CARD_HEADER = "Current Stats";

/** A Program module for the Dashboard */
export const ProgramCard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [allPrograms, setAllPrograms] = useState(0);
  const [activePrograms, setActivePrograms] = useState(0);
  const [deniedPrograms, setDeniedPrograms] = useState(0);

  useEffect(() => {
    programClient
      .getCurrentStats()
      .then((response) => {
        setActivePrograms(response.active_programs);
        setAllPrograms(response.all_programs);
        setDeniedPrograms(response.denied_programs);
      })
      .catch((e) => setError(true))
      .finally(() => setIsLoading(false));
  }, [setActivePrograms, setAllPrograms, setDeniedPrograms, setIsLoading]);

  return (
    <Card
      variant="outlined"
      aria-label="program dashboard module"
      className="uw-card"
    >
      <YarsCardHeader title={CARD_HEADER} component="h2" />
      <CardContent>
        <OrgSwitcher />
        {!isLoading && !error && (
          <List dense={true} className="details-list">
            <ListItem>
              <ListItemAvatar>
                <Avatar aria-labelledby="activeProgramsCount">
                  {activePrograms}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="activeProgramsCount"
                secondary="Active programs"
              />
            </ListItem>
            <ListItem component={NavLinkForward} to="/programs">
              <ListItemAvatar>
                <Avatar aria-labelledby="allProgramsCount">
                  {allPrograms}
                </Avatar>
              </ListItemAvatar>
              <ListItemText id="allProgramsCount" secondary="All programs" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar aria-labelledby="deniedProgramsCount">
                  {deniedPrograms}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id="deniedProgramsCount"
                secondary="Denied programs"
              />
            </ListItem>
          </List>
        )}
        {error && (
          <Typography color="error" align="center" role="alert">
            Could not retrieve programs
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ProgramCard;
